import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { __LOGOUT__, __URL__, __USER__ } from '../../../common/configs/config';
import { useContext } from 'react';
import { AccountContext } from '../../../App';
import Icon32 from "../../ui-elements/Icon32";
import { AppName } from '../../../common/constants';

export default function Header() {
	const Account = useContext(AccountContext);
	const navigate = useNavigate();
	function clickLink(e) {
		e.preventDefault();
		navigate(e.target.pathname);
	}
	return (
		<>
			<Navbar as="header" role="banner" bg="light" expand="lg">
				<Container fluid>
					<Navbar.Brand href={__URL__} onClick={clickLink}>
						{AppName}
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="_header-nav">
						<Nav className="ms-auto">
							<NavDropdown title={<>
									<span>{Account['UserName']}</span>
									<Icon32 />
								</>} id="_header-account-navDropdown" align='end' className='w-auto p-0'>
									<NavDropdown.Item href={__LOGOUT__}>ログアウト</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}
