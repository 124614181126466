import { useState } from 'react';

let init_timeout = 5000;

/**
 * @param MessageObject:{ type:'info'|'success',message:string|array,title:null|string,timeout:int,code:int|null,abstract:null|string}
 */
export default function useInfo() {
	const [MessageObject, setMessageObject] = useState(null);
	const [TimeOut, setTime] = useState(init_timeout);
	function setMessage(message) {
		setMessageObject(
			Object.assign({ type: 'info', message: null, title: null, abstract: null, code: null, timeout: TimeOut }, message)
		);
	}
	function closeMessage() {
		setMessageObject(null);
	}
	function changeTimeout(time) {
		setTime(time);
	}
	function Init() {
		setMessageObject(null);
		setTime(init_timeout);
	}
	function Clear() {
		setMessageObject(null);
	}
	return {
		MessageObject,
		setMessage,
		TimeOut,
		changeTimeout,
		Init,
		Clear,
		closeMessage,
	};
}
