import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import AccountsContext from '../../common/contexts/AccountsContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import Fetch from '../../components/functional/Fetch';
import Upload from '../../components/functional/Upload';
import Icon32 from '../../components/ui-elements/Icon32';
import { CatchError } from '../../common/constants';
import UserIcon from "../../common/assets/common/user.svg";

let uploaded_icon = '';
export default function AccountForm({ id = null }) {
	const [Account, setAccount] = useState();
	const [Validated, setValidated] = useState(false);
	const [IconUrl, setIconUrl] = useState(UserIcon);
	const Accounts = useContext(AccountsContext);
	const Loading = useContext(LoadingContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	const AccountName = useRef();
	const DisplayName = useRef();
	const Message = useRef();
	const Icon = useRef();
	useEffect(() => {
		switch (id) {
			case 'new':
				setAccount({
					AccountName: '',
					DisplayName: '',
					message: '',
				});
				break;
			default:
				if (Accounts['Accounts'][id]) {
					setAccount(Accounts['Accounts'][id]);
					setIconUrl(`${Accounts['Accounts'][id]['AccountIcon']['icons']['IconLink']}tiny`);
				}
				break;
		}
	}, [id, Accounts]);
	/**
	 * 画像変更&プレビュー
	 *
	 * @param {*} e
	 */
	function changeIcon(e) {
		if (e.target.files[0]) {
			setIconUrl(URL.createObjectURL(e.target.files[0]));
		}
	}
	/**
	 * Submit実行
	 *
	 * @param {*} e
	 * @return {*}
	 */
	function actionSubmit(e) {
		//Loading['Init'](10);
		e.preventDefault();
		if (e.target.checkValidity() === false) {
			//バリデーションエラー
			setValidated(true);
			Loading['setLoading'](false);
			return;
		}
		//アイコン画像アップロード
		if (Icon.current.value) {
			let fd = new FormData();
			fd.append('file', Icon.current.files[0]);
			Upload({ formData: fd, type: 'icon' }).then((data) => {
				if (data['result']) {
					uploaded_icon = data['payloads']['IconId'];
					setIconUrl(`${data['payloads']['icons']['IconLink']}tiny`);
					Icon.current.value = '';
					submit(uploaded_icon);
					Loading['AddProcess']();
				}
			});
		} else {
			submit(uploaded_icon);
			Loading['AddProcess']();
		}
	}
	/**
	 * データ送信
	 *
	 * @param {*} [IconId=null]
	 */
	function submit(IconId = null) {
		//アカウント情報送信
		let sendInfo = {
			path: 'user/account',
			method: 'POST',
		};
		if (id !== 'new') {
			sendInfo['path'] += `/${Account['AccountId']}`;
			sendInfo['method'] = 'PUT';
		}
		Loading['AddProcess']();
		sendInfo['body'] = {
			AccountName: AccountName.current.value,
			DisplayName: DisplayName.current.value,
			message: Message.current.value,
		};
		if (IconId) {
			sendInfo['body']['AccountIcon'] = IconId;
		}
		Loading['AddProcess']();
		Fetch(sendInfo)
			.then((data) => {
				if (data['result']) {
					Info['setMessage']({
						type: 'info',
						title: id === 'new' ? 'アカウント登録完了' : 'アカウント情報変更完了',
						message: '',
					});
					let account = Accounts['Accounts'].concat();
					if (id === 'new') {
						account.push(data['payloads']);
					} else {
						account[id]=data['payloads'];
					}
					Accounts['setAccounts'](account);
					setValidated(false);
				} else {
					Info['setMessage'](Object.assign(data['error'], { type: 'warning', timeout: 0 }));
				}
				Loading['AddProcess']();
				Loading['setLoading'](false);
			})
			.catch(() => {
				Error['setMessage'](CatchError);
				Loading['AddProcess']();
				Loading['setLoading'](false);
			});
	}
	if (Account) {
		return (
			<Form noValidate validated={Validated} className="mt-3" onSubmit={actionSubmit}>
				<Form.Group as={Row} className="mb-3" controlId="_AccountForm-AccountName">
					<Form.Label column sm={2} className="text-end">
						アカウントID*
					</Form.Label>
					<Col sm={10}>
						<InputGroup>
							<InputGroup.Text>@</InputGroup.Text>
							<Form.Control
								type="text"
								placeholder="アカウントID"
								defaultValue={Account['AccountName']}
								pattern="([a-zA-Z0-9*_\-\.]{6,12})$"
								required
								ref={AccountName}
							/>
							<Form.Control.Feedback type="invalid">
								6-12文字の半角英数字記号("-","_",".","*")で入力してください
							</Form.Control.Feedback>
						</InputGroup>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3" controlId="_AccountForm-AccountName">
					<Form.Label column sm={2} className="text-end">
						表示名*
					</Form.Label>
					<Col sm={10}>
						<Form.Control
							type="text"
							placeholder="表示名"
							defaultValue={Account['DisplayName']}
							maxLength={30}
							required
							ref={DisplayName}
						/>
						<Form.Control.Feedback type="invalid">30文字以内で入力してください</Form.Control.Feedback>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3" controlId="_AccountForm-AccountName">
					<Form.Label column sm={2} className="text-end">
						一言メッセージ
					</Form.Label>
					<Col sm={10}>
						<Form.Control
							as="textarea"
							placeholder="一言メッセージ"
							defaultValue={Account['message']}
							maxLength={512}
							ref={Message}
						/>
						<Form.Control.Feedback type="invalid">512文字以内で入力してください</Form.Control.Feedback>
					</Col>
				</Form.Group>
				<Form.Group as={Row} className="mb-3" controlId="_AccountForm-AccountName">
					<Form.Label column sm={2} className="text-end">
						アイコン
					</Form.Label>
					<Col sm={10}>
						<Icon32 src={IconUrl} />
						<Form.Control type="file" accept=".jpg,.jpge,.png,.gif" className="mt-2" ref={Icon} onChange={changeIcon} />
					</Col>
				</Form.Group>
				<Row>
					<Col sm={2}></Col>
					<Col sm="auto">
						<Button variant="primary" type="submit">
							{id === 'new' ? '登録' : '変更'}
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
	return;
}
