import { createContext } from 'react';

/**
 * @param MessageObject:{ type:'info'|'success',message:null|string|array,title:null|string}
 * @param ErrorObject: {type:'danger'|'warning',abstarct:null|string,title:null|string,message:null|array,code:null|int}
 */
const variables = {
	MessageObject: null,
	setMessage: () => {},
	TimeOut: 10000,
	changeTimeout: () => {},
	Init: () => {},
	Clear: () => {},
	closeMessage: () => {},
};
const InfoContext = createContext(variables);
export const ErrorContext = createContext(variables);

export default InfoContext;
