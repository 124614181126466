import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import { changeTitle } from '../../components/functional';
import AccountForm from '../../features/AccountForm';

export default function Edit() {
	changeTitle('アカウント編集');
	const { id } = useParams();
	const breadCrumb = useContext(BreadCrumbContext);
	useEffect(() => {
		breadCrumb['setItems']([{ link: '/account', text: 'アカウント設定' }]);
	}, []);
	return (
		<>
			<AccountForm id={id} />
		</>
	);
}
