import { useNavigate } from 'react-router-dom';
import { createKey } from '../../functional';
import './style.css';

export default function BreadCrumb({ items = [], currentPage = document.title.split('|')[0] }) {
	const navigate = useNavigate();
	function moveTo(e) {
		e.preventDefault();
		navigate(e.target.pathname);
	}
	return (
		<ul className="BreadCrumb-wrapper" role="nav">
			{items.map((item) => {
				if (item['link']) {
					return (
						<li key={createKey()} className="BreadCrumb-item">
							<a href={item['link']} onClick={moveTo} className="BreadCrumb-link">
								{item['text']}
							</a>
						</li>
					);
				}
				return <li className="BreadCrumb-currentPage BreadCrumb-item" key={createKey()}>{item['text']}</li>
			})}
		</ul>
	);
}
