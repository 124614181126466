import { useContext, useEffect, useState } from 'react';
import { createKey } from '../../../components/functional';
import AccountContext from '../../contexts/AccountsContext';
import LoadingContext from '../../contexts/LoadingContext';
import InfoContext, { ErrorContext } from '../../contexts/InfoContext';
import { __API__ } from '../../configs/config';
import setMessage from '../../../components/functional/setMessage';

let status = 0;

/**
 * Ajax通信用フック
 *
 * @export
 * @return {*} {send: send()}
 */
export default function useFetch() {
	const Accounts = useContext(AccountContext);
	const Loading = useContext(LoadingContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);

	const setMes = new setMessage(Info, Error, Loading);

	const [Status, setStatus] = useState(status);

	useEffect(() => {
		if (Status) {
			Loading['setLoading'](true);
		} else {
			Loading['setLoading'](false);
		}
	}, [Status]);

	/**
	 * Ajax通信
	 *
	 * @param {string} path
	 * @param {?callback} [successCallback=null]
	 * @param {object} [options={
	 * 			accountId: Accounts['CurrentAccount']['AccountId'],
	 * 			method: 'GET',
	 * 			body: [],
	 * 			bodyType: 'JSON',
	 * 			headers: {},
	 * 			version: 1,
	 * 			errorCallback: null,
	 * 			count: 0,
	 * 		}]
	 */
	function send(path, successCallback = null, options = {}) {
		options = Object.assign(
			{
				//accountId: Accounts['CurrentAccount']['AccountId'],
				method: 'GET',
				body: [],
				bodyType: 'JSON',
				headers: {},
				version: 1,
				errorCallback: null,
				count: 0,
			},
			options
		);
		setStatus(++status);
		let sendOption = {
			method: 'POST',
			headers: Object.assign(
				{
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Licsss-Account': options['accountId'],
					'Licsss-Path': path,
					'Licsss-Method': options['method'],
					'Licsss-Nonce': createKey(),
					'Licsss-Version': options['version'],
					'X-XSRF-TOKEN': sessionStorage.getItem('XSRF-TOKEN'),
				},
				options['headers']
			),
			credentials: 'include', //__DEV__?'include':'same-origin', //本番はsame-origin
		};

		//body挿入
		if (options['method'] !== 'GET') {
			sendOption['body'] = options['bodyType'] === 'JSON' ? JSON.stringify(options['body']) : options['body'];
		}

		//データ取得
		fetch(`${__API__}`, sendOption)
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}
				if (res.status !== 204) {
					return res.json();
				}
				return {
					nonce: sendOption['headers']['Licsss-Nonce'],
				};
			})
			.then((res) => {
				finish();
				if (!res['nonce'] || res['nonce'] !== sendOption['headers']['Licsss-Nonce']) {
					throw new Error('NONCEが一致しません');
				}
				if (res['error']) {
					setMes['addError'](res['error']);
				}
				if (successCallback === 'default') {
					setMes['addSuccess']();
				} else if (successCallback !== null) {
					successCallback(res);
				}
			})
			.catch((error) => {
				finish();
				if (options['count'] < 2) {
					++options['count'];
					setTimeout(() => {
						send(path, successCallback, options);
					}, 500);
					return;
				}
				if (options['errorCallback'] !== null) {
					options['errorCallback'](error);
				}
				setMes['addNetError'](error);
			});
	}
	function finish() {
		setStatus(--status);
	}
	return {
		send: send,
	};
}
