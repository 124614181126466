import { useContext, useEffect } from 'react';
import { Button, ButtonGroup, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AccountsContext from '../../common/contexts/AccountsContext';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import { changeTitle, createKey } from '../../components/functional';
import Icon16 from '../../components/ui-elements/Icon16';
import Icon32 from '../../components/ui-elements/Icon32';
import EditImg from './../../common/assets/common/edit16x16_white.png';
import TrashImg from './../../common/assets/common/trash16x16_white.png';

export default function Account() {
	changeTitle('アカウント設定');
	const navigate = useNavigate();
	const Accounts = useContext(AccountsContext);
	const breadCrumb = useContext(BreadCrumbContext);
	useEffect(()=>{
		breadCrumb['setItems']([]);
		breadCrumb['changeVisible'](true);
	},[]);
	
	function moveTo(e) {
		e.preventDefault();
		let pathname = e.target.pathname;
		if (!pathname) {
			pathname = e.target.parentNode.pathname;
		}
		if (!pathname) {
			return;
		}
		navigate(pathname);
	}
	return (
		<>
			<Row>
				{Accounts['Accounts'] && (
					<Col sm="auto">
						<Table striped responsive>
							<thead>
								<tr>
									<td>アカウント</td>
									<td>一言メッセージ</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								{Accounts['Accounts'].map((account, idx) => (
									<tr key={createKey()}>
										<td>
											<Icon32 src={`${account['AccountIcon']['icons']['IconLink']}tiny`} />
											{account['DisplayName']}
											(@{account['AccountName']})
										</td>
										<td>{account['message']}</td>
										<td>
											<ButtonGroup>
												<Button variant="success" as="a" href={`/account/${idx}/edit`} onClick={moveTo}>
													<Icon16 src={EditImg} className="ms-0" onClick />
													編集
												</Button>
												<Button
													variant="danger"
													as="a"
													href={`/account/${idx}/delete`}
													onClick={moveTo}
												>
													<Icon16 src={TrashImg} className="ms-0" />
													削除
												</Button>
											</ButtonGroup>
										</td>
									</tr>
								))}
							</tbody>
							{Accounts['Limit'] > Accounts['Accounts'].length && (
								<tfoot>
									<tr>
										<td colSpan={3} className="text-end">
											<Button variant="primary" href="/account/new" onClick={moveTo}>
												新規作成
											</Button>
										</td>
									</tr>
								</tfoot>
							)}
						</Table>
					</Col>
				)}
			</Row>
		</>
	);
}
