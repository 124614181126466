// 本番用設定ファイル

export const base_url = 'https://licsss.com/';
export const api_url = 'https://api.licsss.com/';
export const file_upload_url = 'https://file.licsss.com/upload';
export const icon_upload_url = 'https://icon.licsss.com/upload';
export const login_url = 'https://auth.licsss.com';
export const logout_url = 'https://auth.licsss.com/logout';
export const user_url = 'https://account.licsss.com/';
export const chat_url = 'https://chat.licsss.com/';
export const csrf_url = 'csrf-cookie';
