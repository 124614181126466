import { createKey } from '..';
import { __FILE_UPLOAD__, __ICON_UPLOAD__ } from '../../../common/configs/config';

/**
 *
 *
 * @export
 * @param {*} { accountId = 0, formData = null, headers={}, type = 'file'|'icon' }
 * @return {*}
 */
export default function Upload({ accountId = 0, formData = null, headers = {}, type = 'file' }) {
	let send = {
		method: 'POST',
		headers: Object.assign(
			{
				Accept: 'application/json',
				//'Content-Type': 'multipart/form-data',
				'Licsss-Account': accountId,
				'Licsss-Nonce': createKey(),
				'Origin': window.location.origin,
				'X-XSRF-TOKEN': sessionStorage.getItem('XSRF-TOKEN'),
			},
			headers
		),
		credentials: 'include', //__DEV__?'include':'same-origin', //本番はsame-origin
	};
	if (formData) {
		send['body'] = formData;
	}
	return new Promise((resolve, reject) => {
		fetch(type === 'file' ? __FILE_UPLOAD__ : __ICON_UPLOAD__, send)
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				if (res['nonce'] === send['headers']['Licsss-Nonce']) {
					return resolve(res);
				}
				return reject(res);
			})
			.catch((error) => {
				return reject(error);
			});
	});
}
