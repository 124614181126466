import { useContext, useEffect } from 'react';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import { changeTitle } from '../../components/functional';
import AccountForm from '../../features/AccountForm';

export default function New() {
	changeTitle('アカウント作成');
	const breadCrumb = useContext(BreadCrumbContext);
	useEffect(() => {
		breadCrumb['setItems']([{ link: '/account', text: 'アカウント設定' }]);
	}, []);
	return (
		<>
			<AccountForm id="new" />
		</>
	);
}
