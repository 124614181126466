export default class setMessage {
	constructor(Info, Error, Loading = null) {
		this.Info = Info;
		this.Error = Error;
		this.Loading = Loading;
	}

	addError(error) {
		this.Error['setMessage'](Object.assign({ type: 'warning', timeout: 0 }, error));
		if (this.Loading) {
			this.Loading['setLoading'](false);
		}
	}
	addNetError(error, message = null) {
		this.Error['setMessage']({
			type: 'danger',
			timeout: 0,
			abstract: 'ERROR',
			title: 'エラー',
			message:
				message !== null
					? '通信エラーまたはスクリプトエラーが発生しました'
					: ['通信エラーまたはスクリプトエラーが発生しました', message],
		});
		if (this.Loading) {
			this.Loading['setLoading'](false);
		}
	}
	addSuccess(MessageObject) {
		this.Info['setMessage'](Object.assign({ type: 'info', title: '処理完了' }, MessageObject));
		if (this.Loading) {
			this.Loading['setLoading'](false);
		}
	}
}
