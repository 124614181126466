import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './common/styles/index.css';
import { __API__, __CSRF__, __LOGIN__ } from './common/configs/config';
import { cookies } from './components/functional';
import Error500 from './pages/error/500';
import Fetch from './components/functional/Fetch';

// csrf初期化
const root = ReactDOM.createRoot(document.getElementById('root'));
fetch(`${__API__}${__CSRF__}`, {
	credentials: 'include',
})
	.then((res) => res.json())
	.then((data) => {
		const CsrfToken = cookies('XSRF-TOKEN');

		if (CsrfToken === undefined) {
			// 取得失敗
			XsrfTokenError();
			return;
		}

		if (!data['payloads']['auth']) {
			// 未ログイン
			window.location.href = `${__LOGIN__}?redirect=${encodeURI(window.location.href)}`;
			return;
		}

		sessionStorage.setItem('XSRF-TOKEN', CsrfToken);

		// アカウント確認

		if (localStorage.getItem('account') === null) {
			localStorage.setItem('account', 0);
		}

		// アカウント取得
		Fetch({
			path: 'init/user',
			method: 'GET',
		})
			.then((data) => {
				if (data['result']) {
					root.render(<App init={data['payloads']} />);
				} else {
					AccountError();
				}
			})
			.catch(() => {
				AccountError();
			});
	})
	.catch(() => {
		XsrfTokenError();
	});

function AccountError() {
	root.render(<Error500 />);
}

function XsrfTokenError() {
	root.render(<Error500 />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
