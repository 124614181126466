import { Navbar, Container, Nav } from 'react-bootstrap';
import { __CHAT__, __LiCSSs__, __LOGOUT__, __URL__, __USER__ } from '../../../common/configs/config';
import { Year } from '../../../common/constants';
import './style.css';

export default function Footer() {
	return (
		<Navbar
			as="footer"
			role="contentinfo"
			bg="dark"
			className="text-muted position-absolute bottom-0 w-100"
			expand="lg"
		>
			<Container fluid>
				<Navbar.Brand href={__LiCSSs__} target="_blank" className="text-light">
					LiCSSs
				</Navbar.Brand>
				<Nav className="mx-auto footer-nav">
					<Nav.Link href={__USER__} className="text-light" target="_blank">
						アカウント
					</Nav.Link>
					<Nav.Link href={__CHAT__} className="text-light" target="_blank">
						チャット
					</Nav.Link>
				</Nav>
				<div className="me-3">
					&copy; {Year}{' '}
					<a href={__LiCSSs__} target="_blank" className="text-muted text-decoration-none">
						LiCSSs
					</a>
					.
				</div>
			</Container>
		</Navbar>
	);
}
