import { createContext } from 'react';

const AccountsContext = createContext({
	Accounts: [],
	setAccounts: () => {},
	Limit: 5,
	setLimit: () => {},
});

export default AccountsContext;
