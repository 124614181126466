import { useContext, useEffect } from "react";
import BreadCrumbContext from "../../common/contexts/BreadCrumbContext";
import { changeTitle } from "../../components/functional";

export default function Error({ title, content }) {
	changeTitle(title);
	const breadCrumb = useContext(BreadCrumbContext);
	useEffect(()=>{
		breadCrumb['changeVisible'](true);
		breadCrumb['setItems']([]);
	},[]);
	return (
		<div className="p-3">
			<h2>{title}</h2>
			<div className="mt-3">{content}</div>
		</div>
	);
}
