const date = new Date();

export const Year = date.getFullYear();

/**
 * Fetchの例外
 */
export const CatchError = {
	type: 'danger',
	title: '通信エラー',
	abstract: 'ERROR',
	message: '通信エラーが発生しました',
	timeout: 0,
};

export const AppName = "LiCSSsアカウント";