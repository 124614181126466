// 設定ファイル

import {
	base_url as dev_base_url,
	api_url as dev_api_url,
	file_upload_url as dev_file_upload_url,
	icon_upload_url as dev_icon_upload_url,
	login_url as dev_login_url,
	logout_url as dev_logout_url,
	user_url as dev_user_url,
	chat_url as dev_chat_url,
	csrf_url as dev_csrf_url
} from './dev';
import {
	base_url as prod_base_url,
	api_url as prod_api_url,
	file_upload_url as prod_file_upload_url,
	icon_upload_url as prod_icon_upload_url,
	login_url as prod_login_url,
	logout_url as prod_logout_url,
	user_url as prod_user_url,
	chat_url as prod_chat_url,
	csrf_url as prod_csrf_url
} from './production';

export const env = window.location.hostname === 'localhost' ? "dev" : "production"; // 環境

export let __DEV__;
export let __API__;
export let __FILE_UPLOAD__;
export let __ICON_UPLOAD__;
export let __URL__;
export let __LOGIN__;
export let __LOGOUT__;
export let __USER__;
export let __CHAT__;
export let __CSRF__;
export const __LiCSSs__ = 'https://licsss.jp/';
export const UserIconId = env === 'dev' ? '2044fc44-5a76-430b-8300-512cfc88060c' : 'e56a6ee5-4634-46f7-9e74-442d0209ecc5';

switch (env) {
	case 'dev':
		__DEV__ = true;
		__API__ = dev_api_url;
		__FILE_UPLOAD__ = dev_file_upload_url;
		__ICON_UPLOAD__ = dev_icon_upload_url;
		__URL__ = dev_base_url;
		__LOGIN__ = dev_login_url;
		__LOGOUT__ = dev_logout_url;
		__USER__ = dev_user_url;
		__CHAT__ = dev_chat_url;
		__CSRF__ = dev_csrf_url;
		break;
	case 'production':
		__DEV__ = false;
		__API__ = prod_api_url;
		__FILE_UPLOAD__ = prod_file_upload_url;
		__ICON_UPLOAD__ = prod_icon_upload_url;
		__URL__ = prod_base_url;
		__LOGIN__ = prod_login_url;
		__LOGOUT__ = prod_logout_url;
		__USER__ = prod_user_url;
		__CHAT__ = prod_chat_url;
		__CSRF__ = prod_csrf_url;
		break;
}
