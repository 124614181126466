import { createContext } from 'react';

const BreadCrumbContext = createContext({
	CurrentPage: true,
	Visible: true,
	Items: [],
  ItemObjects: [],
	changeCurrentPage: () => {},
	changeVisible: () => {},
	createItemObject: () => {},
	setItems: () => {},
});

export default BreadCrumbContext;
