import { AppName } from "../../common/constants";
/**
 * ページタイトル更新
 *
 * @export
 * @param {*} title
 */
export function changeTitle(title) {
	document.title = (title?`${title} | `:"") + AppName;
}

/**
 * ランダム文字列生成
 *
 * @export
 * @return {*}
 */
export function createKey() {
	return Math.random().toString(32).substring(2);
}

/**
 * cookie取得
 *
 * @export
 * @param {*} [key=null]
 * @return {*}
 */
export function cookies(key = null) {
	let cookies = {};
	document.cookie.split('; ').map((cookie) => {
		let tmp = cookie.split('=');
		cookies[tmp[0]] = decodeURIComponent(tmp[1]);
	});
	if (key === null) {
		return cookies;
	}
	return cookies[key];
}
