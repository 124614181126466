import { createContext, useContext, useEffect } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
import AccountsContext from './common/contexts/AccountsContext';
import BreadCrumbContext from './common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from './common/contexts/InfoContext';
import LoadingContext from './common/contexts/LoadingContext';
import useAccounts from './common/hooks/useAccounts';
import useBreadCumrb from './common/hooks/useBreadCrumb';
import useInfo from './common/hooks/useInfo';
import useLoading from './common/hooks/useLoading';
import Fetch from './components/functional/Fetch';
import Router from './components/functional/Router';
import Footer from './components/layouts/Footer';
import Header from './components/layouts/Header';
import Main from './components/layouts/Main';

export const AccountContext = createContext();

export default function App({ init }) {
	const accounts = useAccounts();
	const loading = useLoading();
	const info = useInfo();
	const error = useInfo();
	const breadCrumb = useBreadCumrb();
	return (
		<AccountContext.Provider value={init['user']}>
			<AccountsContext.Provider value={accounts}>
				<LoadingContext.Provider value={loading}>
					<InfoContext.Provider value={info}>
						<ErrorContext.Provider value={error}>
							<BreadCrumbContext.Provider value={breadCrumb}>
								<AccountsContextGet account={init['account']}>
									<Loading>
										<BrowserRouter basename="/">
											<div className="position-relative" style={{ minHeight: '100vh' }}>
												<Header />
												<Main>
													<Router />
												</Main>
												<Footer />
											</div>
										</BrowserRouter>
									</Loading>
								</AccountsContextGet>
							</BreadCrumbContext.Provider>
						</ErrorContext.Provider>
					</InfoContext.Provider>
				</LoadingContext.Provider>
			</AccountsContext.Provider>
		</AccountContext.Provider>
	);
}

//Loading
function Loading({ children }) {
	const loading = useContext(LoadingContext);
	return (
		<>
			{loading['Loading'] ? (
				<Modal show={true} centered>
					<Modal.Body>
						<div className="fs-3 mb-3">通信中...</div>
						<ProgressBar>
							<ProgressBar
								variant="success"
								label={`${loading['CurrentProcess']}/${loading['AllProcess']}`}
								animated
								now={loading['CurrentProcess']}
								max={loading['AllProcess']}
							/>
							<ProgressBar
								variant="light"
								animated
								now={loading['AllProcess'] - loading['CurrentProcess']}
								max={loading['AllProcess']}
							/>
						</ProgressBar>
					</Modal.Body>
				</Modal>
			) : (
				''
			)}
			{children}
		</>
	);
}

//アカウントcontext
function AccountsContextGet({ children, account }) {
	const Accounts = useContext(AccountsContext);
	useEffect(() => {
		if (Accounts['Accounts'].length === 0) {
			Accounts['setAccounts'](account['accounts']);
			Accounts['setLimit'](account['limit']);
		}
	}, []);
	return <>{children}</>;
}
