import { useState } from 'react';

export default function useAccounts() {
	const [Accounts, setAccounts] = useState([]);
	const [Limit, setLimit] = useState(5);
	return {
		Accounts,
		setAccounts,
		Limit,
		setLimit,
	};
}