import { useState } from 'react';

let current_process = 0;
export default function useLoading() {
	const [Loading, setLoading] = useState(false);
	const [AllProcess,setAllProcess] = useState(1);
	const [CurrentProcess,setCurrentProcess]=useState(0);
	function AddProcess(){
		++current_process;
		setCurrentProcess(current_process);
	}
	function Init(AllProcess){
		current_process = 0;
		setLoading(true);
		setAllProcess(AllProcess);
		setCurrentProcess(0);
	}
	return {
    Loading,
    setLoading,
		AllProcess,
		setAllProcess,
		CurrentProcess,
		setCurrentProcess,
		AddProcess,
		Init
	};
}