import { useContext, useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { AccountContext } from '../App';
import BreadCrumbContext from '../common/contexts/BreadCrumbContext';
import { changeTitle } from '../components/functional';

export default function Home() {
	changeTitle('');
	const Account = useContext(AccountContext);
	const breadCrumb = useContext(BreadCrumbContext);
	const navigate = useNavigate();

	useEffect(()=>{
		breadCrumb['setItems']([]);
	},[]);
	function moveTo(e){
		e.preventDefault();
		navigate(e.target.pathname);
	}
	return (
		<>{/*
			<Row className='mb-3'>
				<Col className='fs-1'>こんにちは，{Account['UserName']}さん！</Col>
			</Row>*/}
			<Row>
				<Col sm={6}>
					<Card>
						<Card.Header>アカウント関連</Card.Header>
						<Card.Body>
							<ListGroup>
								<ListGroup.Item href='/account' onClick={moveTo} action>アカウント一覧</ListGroup.Item>
								<ListGroup.Item href='/account/new' onClick={moveTo} action>アカウント追加</ListGroup.Item>
								<ListGroup.Item href='/app' onClick={moveTo} action>利用アプリ登録</ListGroup.Item>
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
}
