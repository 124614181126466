import Error from '../../templates/Error';

export default function Error404() {
	function content() {
		return (
			<>
				<p>お探しのページは見つかりませんでした。</p>
				<p>URLをご確認ください。</p>
			</>
		);
	}
	return <Error title={'ページが見つかりません'} content={content()} />;
}
