import { useContext, useEffect } from 'react';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CatchError } from '../../common/constants';
import AccountsContext from '../../common/contexts/AccountsContext';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import { changeTitle } from '../../components/functional';
import Fetch from '../../components/functional/Fetch';
import Icon32 from '../../components/ui-elements/Icon32';

export default function Delete() {
	changeTitle('アカウント削除');
	const { id } = useParams();
	const breadCrumb = useContext(BreadCrumbContext);
	const Accounts = useContext(AccountsContext);
	const Loading = useContext(LoadingContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
	useEffect(() => {
		breadCrumb['setItems']([{ link: '/account', text: 'アカウント設定' }]);
	}, []);

	/**
   * アカウント削除
   *
   */
  function deleteAccount() {
		Loading['Init'](1);
		Fetch({ path: `user/account/${Accounts['Accounts'][id]['AccountId']}`, method: 'DELETE' })
			.then((data) => {
				if (data['result']) {
					Info['setMessage']({
						type: 'info',
						title: 'アカウント削除完了',
					});
					let account = Accounts['Accounts'].concat();
					Accounts['setAccounts'](account.splice(id, 1));
				} else {
					Error['setMessage'](Object.assign({ type: 'warning', abstract: 'ERROR', timeout: 0 }, data['error']));
				}
				Loading['setLoading'](false);
			})
			.catch(() => {
				Error['setMessage'](CatchError);
				Loading['setLoading'](false);
			});
	}
	if (Accounts['Accounts'][id]) {
		return (
			<Row className="m-3">
				<Col sm="auto">
					<Card>
						<Card.Body>
							<Icon32 src={`${Accounts['Accounts'][id]['AccountIcon']['icons']['IconLink']}tiny`} />
							{Accounts['Accounts'][id]['DisplayName']}
							(@{Accounts['Accounts'][id]['AccountName']})<div>{Accounts['Accounts'][id]['message']}</div>
						</Card.Body>
					</Card>
				</Col>
				<Col sm="auto">
					<Alert variant="danger">
						このアカウントを本当に削除してもよろしいですか？
						<br />
						アカウントを削除すると，データを復元することができません。
						<div className="mt-3 text-end">
							<Button variant="dark" type="button" onClick={deleteAccount}>
								削除
							</Button>
						</div>
					</Alert>
				</Col>
			</Row>
		);
	}
	return;
}
