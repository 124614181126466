import { createContext } from 'react';

const LoadingContext = createContext({
	Loading: false,
	setLoading: () => {},
	AllProcess: 1,
	setAllProcess: () => {},
	CurrentProcess: 0,
	setCurrentProcess: () => {},
	AddProcess: () => {},
	Init: () => {},
});

export default LoadingContext;
