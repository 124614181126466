// 開発環境用設定ファイル

export const base_url = 'http://localhost:3001/';
export const login_url = 'http://localhost/licsss/api/v1/public/login';
export const api_url = 'http://localhost/licsss/api/v1/public/';
export const file_upload_url = 'http://localhost/licsss/api/v1/public/file/file';
export const icon_upload_url = 'http://localhost/licsss/api/v1/public/file/icon';
export const logout_url = 'http://localhost/licsss/api/v1/public/logout';
export const user_url = 'http://localhost/licsss/api/v1/public/profile/';
export const chat_url = 'http://localhost/licsss/chat/';
export const csrf_url = 'csrf-cookie';

