import Error from '../../templates/Error';

export default function Error500() {
	function content() {
		return (
			<>
				<p>サーバエラーです。<br />管理者にご連絡ください。</p>
			</>
		);
	}
	return <Error title={'サーバエラー'} content={content()} />;
}
