import { useEffect, useState } from 'react';
import { AppName } from '../../constants';

export default function useBreadCumrb() {
	const [Items, setItems] = useState([]);
	const [ItemObjects, setItemObjects] = useState([]);
	const [Visible, setVisible] = useState(true);
	const [CurrentPage, setCurrentPage] = useState(true);
	function changeCurrentPage(visible = true) {
		setCurrentPage(visible);
	}
	function changeVisible(visible = true) {
		setVisible(visible);
	}
	function createItemObject(link, text) {
		return {
			link: link,
			text: text,
		};
	}
	useEffect(() => {
		let items = [];
		if (window.location.pathname !== '/') {
			items.push({
				link: '/',
				text: AppName,
			});
		}
		items = items.concat(Items);
		if (CurrentPage) {
			setItemObjects(items.concat({ text: document.title.split('|')[0] }));
		} else {
			setItemObjects(items);
		}
	}, [Items, CurrentPage]);

	return {
		CurrentPage,
		Visible,
		Items,
		ItemObjects,
		changeCurrentPage,
		changeVisible,
		createItemObject,
		setItems,
	};
}
