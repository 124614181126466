import { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import AccountsContext from '../../common/contexts/AccountsContext';
import BreadCrumbContext from '../../common/contexts/BreadCrumbContext';
import InfoContext, { ErrorContext } from '../../common/contexts/InfoContext';
import LoadingContext from '../../common/contexts/LoadingContext';
import useFetch from '../../common/hooks/useFetch';
import { changeTitle, createKey } from '../../components/functional';
import setMessage from '../../components/functional/setMessage';
import Icon32 from '../../components/ui-elements/Icon32';

const AppName = {
	kakeibo: '家計簿',
	chat: 'チャット',
};

export default function AppUsable() {
	changeTitle('利用アプリ登録');
	const Accounts = useContext(AccountsContext);
	const breadCrumb = useContext(BreadCrumbContext);
	const Loading = useContext(LoadingContext);
	const Info = useContext(InfoContext);
	const Error = useContext(ErrorContext);
  const setMes = new setMessage(Info,Error,Loading);
	const { send } = useFetch();

	const [Apps, setApps] = useState();

	useEffect(() => {
		breadCrumb['setItems']([]);
		breadCrumb['changeVisible'](true);
		send('user/app', (data) => {
			if (data['result']) {
				setApps(data['payloads']);
			}
		});
	}, []);

	/**
	 * 変更結果送信
	 *
	 * @param {*} e
	 */
	function actionSubmit(e) {
		e.preventDefault();
		let body = {};
		for (let i = 0; i < e.target.length; ++i) {
			let name = e.target[i].name.split('.');
			if (name.length === 2) {
				if (!body[name[0]]) {
					body[name[0]] = {};
				}
				body[name[0]][name[1]] = e.target[i].checked;
			}
		}
		send('user/app', (data) => {
      if (data['result']) {
        setApps(data['payloads']);
        setMes['addSuccess']();
      }
    }, { method: 'PUT', body: body });
	}
	return (
		<Form noValidate onSubmit={actionSubmit}>
			<Row>
				{Accounts['Accounts'] && Apps && (
					<Col sm="auto" className="mb-3">
						<Accordion defaultActiveKey={0}>
							{Accounts['Accounts'].map((account, idx) => (
								<Accordion.Item eventKey={idx} key={createKey()}>
									<Accordion.Header>
										<Icon32 src={`${account['AccountIcon']['icons']['IconLink']}tiny`} />
										{account['DisplayName']}
										(@{account['AccountName']})
									</Accordion.Header>
									<Accordion.Body>
										{Object.keys(Apps[account['AccountId']]).map((apps) => (
											<Col sm="auto" key={createKey()}>
												<Form.Check
													type="switch"
													name={`${account['AccountId']}.${apps}`}
													id={`_account-${account['AccountId']}-app-${apps}`}
													label={AppName[apps]}
													defaultChecked={Apps[account['AccountId']][apps]}
												/>
											</Col>
										))}
									</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					</Col>
				)}
			</Row>
			<Row>
				<Col sm="auto">
					<Button variant="primary" type="submit">
						変更
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
